//  System: Whistle It
//    Developer: Neeha Kaleem
//    Date: 4-1-2021
//   Organization: Whistle It
//    Purpose:Purpose of this file to avoid repeted declaration of same variables.All const in this
// file can be use in any other file by import this variable

const passwordRules = [
    (v) => !!v || "Required.",

    (v) =>
    ((v && v.length) >= 6 && (v && v.length) <= 128) ||
    "Password must contain at least 6 characters.",
];
const emailRules = [
    (v) => !!v || "Email Address is required",
    (v) =>
    /[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,3}$/.test(v) || "E-mail must be valid",
];
const required = [(v) => !!v || "Required."];
const otpRules = [
    (v) => !!v || "OTP is required",
    (v) => /[0-9]/.test(v) || "OTP must be Numbers",
    (v) => (v && v.length) <= 4 || "Maximum 4 characters are required",
];
const hourRules = [
    (v) => !!v || "This is a required field",
    (v) => (v && v > 0 && v <= 24) || "Input must lie between 1 - 24",
];
const dayAndMinuteRules = [
    (v) => !!v || "This is a required field",
    (v) => (v && v > 0 && v <= 60) || "Input must lie between 1 - 60",
];
const companyNameRules = [
    (v) =>
    /^[A-Za-z0-9][ .]?([ .,&@!#$-\\']?[ a-zA-Z0-9][ .]?)*$/.test(v) ||
    "Company name only contain alphabet or numbers ",
    (v) =>
    ((v && v.length) >= 2 && (v && v.length) <= 50) ||
    "Company name must lie between 2 - 50 characters long.",
];
const teamNameRules = [
    (v) => !!v || "required",
    //(v) => /[0-9]/.test(v) || "OTP must be Numbers",
    (v) =>
    /^(?=.*[a-zA-Z]).+$/.test(v) ||
    "Team name must contain atleast one alphabet",
    (v) =>
    ((v && v.length) >= 2 && (v && v.length) <= 50) ||
    "Team name must lie between 2 - 50 characters long.",
];

const channelNameRules = [
    (v) => !!v || "This is a required field",
    (v) =>
    ((v && v.length) >= 2 && (v && v.length) <= 64) ||
    "Channel name must lie between 2 - 64 characters long.",
];
const customStatus = [
    (v) => (v && v.length) <= 100 || "Maximum 100 characters are required",
];
const designation = [
    (v) => (v && v.length) <= 50 || "Maximum 50 characters are required",
];
const userNameRule = [
    (v) => !!v || "This is a required field",
    (v) => /^[A-Za-z\\ \\]+$/.test(v) || "Name only contains alphabets",
    (v) =>
    ((v && v.trim().length) > 2 && (v && v.trim().length) <= 50) ||
    "Name must be 3 - 50 characters long.",
];

const ip_rules = [
    (v) =>
    /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(
        v
    ),
];
export {
    passwordRules,
    emailRules,
    required,
    otpRules,
    hourRules,
    dayAndMinuteRules,
    teamNameRules,
    companyNameRules,
    channelNameRules,
    userNameRule,
    ip_rules,
    customStatus,
    designation,

};