
<template>
  <SignIn />
</template>

<script>
import SignIn from "@/components/signIn.vue";
export default {
  components: {
    SignIn,
  },
  data() {
    return {};
  },
};
</script>

<style></style>
