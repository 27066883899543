<!-- System: Super admin
Developer: Hassan Ali
Date: 22-06-2021 
Organization: Whistle it 
Purpose: User can Login his/her account by giving valid credentials.-->

<template>
  <v-row height="100%">
    <v-col
      :cols="$vuetify.breakpoint.smAndDown ? 12 : 1"
      class="d-flex justify-center"
    >
      <img
        src="@/assets/images/whistle-logo.svg"
        :class="
          !$vuetify.breakpoint.smAndDown
            ? ' logo ml-14 mt-8'
            : 'logo ml-4 mt-4 mt-12 '
        "
        alt="Whistle It"
        width="72"
        height="45.47"
      />
    </v-col>
    <v-col
      class="form-view"
      :cols="$vuetify.breakpoint.smAndDown ? 12 : 4"
      :class="$vuetify.breakpoint.smAndDown ? 'px-8 pt-0 ' : 'form px-6'"
    >
      <h1>Log in</h1>
      <p class="credentials py-2">Enter your credentials to proceed</p>
      <v-form ref="form" v-model="valid" lazy-validation class="mt-2">
        <v-text-field
          outlined
          dense
          type="email"
          v-model="email"
          :rules="emailRules"
          label="Email Address*"
          required
          class="py-2"
        ></v-text-field>
        <v-text-field
          outlined
          label="Password*"
          dense
          id="passwordField"
          v-model="password"
          :rules="required"
          :type="visible ? 'text' : 'password'"
          @keyup.native.enter="setSignInInfo()"
          required
          class="py-2 pb-0 mb-n2"
        >
          <template v-slot:append>
            <img
              @click="showHidePassword"
              :src="visible ? password_show : password_hide"
              class="pt-1 pointer"
            />
          </template>
        </v-text-field>
        <span class="d-flex justify-space-between pa-0">
          <v-checkbox
            label="Remember Me"
            required
            class="ma-0 pa-0 checkboxColor"
            
          ></v-checkbox>

          <b class="pointer primary--text"
            ><router-link
              id="forget-password-link"
              to="/enteremail"
              class="pointer text-decoration-none"
            >
              Forgot Password?
            </router-link></b
          >
        </span>

        <div class="justify-center action-btn" align-center>
          <v-btn
            class="mt-8 mb-4 block white--text"
            color="#49113B"
            width="350"
            :disabled="!valid || loginLoader"
            depressed
            @click="setSignInInfo"
          >
            <v-progress-circular indeterminate v-if="loginLoader" size="20">
            </v-progress-circular>
            Login
          </v-btn>

        </div>
      </v-form>
    </v-col>
    <v-col cols="7" v-show="!$vuetify.breakpoint.smAndDown">
      <img :src="imgSource" alt="" class="px-0 bg-img" />
    </v-col>
    <Snackbar
      :snackbar.sync="snackbar"
      :message="message"
      :color="snackbarColor"
    />
  </v-row>
</template>

<script>
// import FormLayout from "./FormLayout";
import { emailRules, required } from "@/Constants/Const";
import Snackbar from "@/components/Snacbar";
import { mapActions, mapGetters } from "vuex";
export default {
  components: {
    // FormLayout,
    Snackbar,
  },

  data: () => ({
    valid: true,
    imgWdth: "100",
    password: "",
    email: "",
    snackbar: false,
    message: "",
    visible: false,
    is_remember: false,
    emailRules: emailRules,
    required: required,
    imgSource: require("@/assets/images/whistle login animation bg 2.svg"),
    password_show: require("@/assets/icons/password_show.svg"),
    password_hide: require("@/assets/icons/password_hide.svg"),
    autofilled: false,
    snackbarColor: "",
    loginLoader: false,
  }),
  computed: {
    ...mapGetters(["getSignInCredentials"]),
  },

  methods: {
    showHidePassword() {
      this.visible = !this.visible;
      let passwordField = document.getElementById("passwordField");
      passwordField.focus();
      setTimeout(() => {
        passwordField.selectionStart = this.password.length;
      }, 0);
    },
    ...mapActions(["SignInCredentials"]),
    setSignInInfo() {
      this.loginLoader = true;
      this.SignInCredentials({
        email: this.email,
        password: this.password,
        is_remember: 0,
      }).then(
        () => {

          this.loginLoader = false;
          this.$router.push("/dashboard");
        },
        (error) => {
          this.loginLoader = false;
          if (error.response.data.error) {
            this.snackbar = true;
            this.message = error.response.data.error;
            this.snackbarColor = "red";
          } else {
            this.snackbar = true;
            this.message = "Something went wrong.";
            this.snackbarColor = "red";
          }
        }
      );
    },
  },

  //send token to IOS webview
};
</script>
<style>
.card-margin {
  margin-top: 15%;
}

.action-btn {
  text-align: center !important;
}
.form {
  margin-top: 10%;
}
.bg-img {
  height: 100%;
  position: absolute;
  right: -1%;
}
.form-view{
  z-index: 3 !important;
}
/* Change the white to any color ;) */
</style>
